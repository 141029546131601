import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../scss/pages/index.scss"

const IndexPage = () => (
  <Layout template="Home">
    <Seo
        title="Home"
        description="Milk Parlor Music Studios are located in Arlington, WA, just a stone's throw from Historic Downtown. "/>

    <section className={ `content-section bg-color-red` }>
        <div className={ `container-lg inner` }>
            <h1>Welcome to the Milk Parlor Music Studios</h1>
            <p className={ `intro-statement` }>
            Milk Parlor Music Studios exist for musicians to steal away from the hustle and bustle of everyday life, to practice and hone their skills,
            develop songs, collaborate together and craft their recordings. Once an active milking parlor, there still are many remnants from it's past,
            most notable is the rustic and homey vibe that makes room for many a magical music creation.
            </p>
        </div>
    </section>

    <section className={ `content-section bg-color-red` }>
        <div className={ `container-lg inner` }>

            <h2>Our Resident Artists</h2>
            <p>We currently have two spaces filled and serving:</p>

            <div className={ `artist-block` }>

                <div className={ `artist` }>
                    <div className={ `inner` }>
                        <a href="https://michelleweavermusic.com" target="_blank" rel="noopener noreferrer">Michelle Weaver</a>
                    </div>
                </div>

                <div className={ `artist` }>
                    <div className={ `inner` }>
                        Breanna Menzies
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section className={ `content-section bg-color-red` }>
        <div className={ `container-lg inner` }>
            <h2>Friends of the Studio</h2>
            <p>
            We love and support our friends.
            Here are some fabulous artists that have in some capacity,
            big or small, contributed to what we are building.
            A huge thanks from the bottom of our hearts</p>

            <div className={ `artist-block squares-sm` }>

                <div className={ `artist` }>
                    <div className={ `inner` }>
                        <a href="https://kristenpalmersings.com" target="_blank" rel="noopener noreferrer">Kristen Palmer</a>
                    </div>
                </div>

                <div className={ `artist` }>
                    <div className={ `inner` }>
                        <a href="https://charleswicklander.bandcamp.com/" target="_blank" rel="noopener noreferrer">Charles Wicklander</a>
                    </div>
                </div>

                <div className={ `artist` }>
                    <div className={ `inner` }>
                        <a href="https://www.bradfordloomis.com/" target="_blank" rel="noopener noreferrer">Bradford Loomis</a>
                    </div>
                </div>

            </div>
        </div>
    </section>

    <section className={ `content-section bg-color-red` }>
        <div className={ `container-lg inner` }>
            <h2>Get in Touch</h2>
            <img
                src="images/email-address.svg"
                alt="Get In Touch"
                loading="lazy"
                className={ `email-address`}
                width={ `500` }
                height={ `16` }
            />
        </div>
    </section>
  </Layout>
)

export default IndexPage
